import * as yup from 'yup'
import {
  requiredIfUsedAsPlaceholder,
  schemaSpec as createSignalSpec,
} from './create'
import FieldNames from '../fields'
import Integrations from '../../../integrations'
import SignalPlaceholders from '../../../signals/form/placeholders'
import Yup from '../../../yup'

const Placeholders = SignalPlaceholders.Placeholders
const pl = Yup.number()

export default function signalUpdateValidationSchema(mandatoryFields) {
  //we need part o fields from createSignals form, so we re-use part of schema (PL is required if signal already has PL)
  const signalsSchema = createSignalSpec()
  return [
    {
      [FieldNames.LEVERAGE]: signalsSchema[FieldNames.LEVERAGE],
      [FieldNames.ENTRY_PRICES]: signalsSchema[FieldNames.ENTRY_PRICES],
      [FieldNames.STOP_PRICES]: signalsSchema[FieldNames.STOP_PRICES],
      [FieldNames.TARGET_PRICES]: signalsSchema[FieldNames.TARGET_PRICES],
      [FieldNames.PL]: Yup.lazy(() =>
        mandatoryFields[FieldNames.PL]
          ? pl.required()
          : pl.when(
              'connectors',
              requiredIfUsedAsPlaceholder(
                'signalUpdated',
                `#${Placeholders.TS_PL}#`
              )
            )
      ),
      //fields unique to updating signal
      [FieldNames.COMMENT]: yup.string().required().trim().min(1).max(80000),
    },
    // step 2
    {
      connectors: yup
        .object({
          telegram: yup
            .object({
              signalUpdated:
                Integrations.Telegram.Connectors.Validators.connectorDataValidator(),
            })
            .nullable(),
        })
        .required(),
    },
  ]
}
