import FieldNames from '../fields'
import Integrations from '../../../integrations'
import SignalPlaceholders from '../../../signals/form/placeholders'
import Yup from '../../../yup'
import TradingView from '../../../trading-view'

const Placeholders = SignalPlaceholders.Placeholders
const validTvIntervals = Object.keys(TradingView.timeIntervals)

const stringContainsPlaceholder = (string, placeholder) =>
  string && string.includes(placeholder)

export const requiredIfUsedAsPlaceholder =
  (dataType, placeholder) => (connectors, schema) =>
    doConnectorsContainPlaceholder(connectors, placeholder, dataType)
      ? schema.required()
      : schema.nullable()

//SIGNAL VALIDATION
const yupPositionValidation = Yup.array()
  .required()
  .of(
    Yup.object().shape({
      price: Yup.number().required('Price is a required field'), //.typeError(intl.formatMessage({id: "SIGNALS.VALIDATION.PRICE_NUMBER"})),
      size: Yup.number()
        .moreThan(0, 'Size must be greater than 0')
        .max(100)
        .required('Size is a required field'), //.typeError(intl.formatMessage({id: "SIGNALS.VALIDATION.SIZE_NUMBER"})),
      hit: Yup.bool(), //.typeError(intl.formatMessage({id: "SIGNALS.VALIDATION.SIZE_NUMBER"})),
    })
  )

export function schemaSpec() {
  //create one validation object from array of objects (array because we use stepper where pages correspond to array item)
  return validationSchema().reduce((a, obj) => ({ ...a, ...obj }), {})
}

const doesTelegramConnectorDataContainPlaceholder = (data, placeholder) => {
  if (!data || !data.enabled) {
    return false
  }
  const sendType = data.sendType
  if (
    sendType === Integrations.Telegram.Connectors.ActionTypes.SEND_PHOTO &&
    (stringContainsPlaceholder(data.sendPhoto.photoUrl, placeholder) ||
      stringContainsPlaceholder(data.sendPhoto.caption, placeholder))
  ) {
    return true
  }
  if (
    sendType === Integrations.Telegram.Connectors.ActionTypes.SEND_MESSAGE &&
    stringContainsPlaceholder(data.sendMessage.message, placeholder)
  ) {
    return true
  }
}

const doesWordpressConnectorContainPlaceholder = (data, placeholder) => {
  if (!data || !data.enabled) {
    return false
  }
  return (
    stringContainsPlaceholder(data.title, placeholder) ||
    stringContainsPlaceholder(data.body, placeholder)
  )
}
const doConnectorsContainPlaceholder = (connectors, placeholder, dataType) =>
  doesTelegramConnectorDataContainPlaceholder(
    connectors?.telegram && connectors?.telegram[dataType],
    placeholder
  ) ||
  doesWordpressConnectorContainPlaceholder(
    connectors?.wordpress && connectors?.wordpress[dataType],
    placeholder
  )

//mandatoryFields based on https://auto1.tech/the-concept-of-subforms-with-formik/
export default function validationSchema() {
  return [
    // step 0
    { [FieldNames.GROUP]: Yup.string().trim().required() },
    // step 1
    {
      [FieldNames.SYMBOL]: Yup.string().trim().required(),
      //if you update regex here make sure regex in SignalTradingViewWidget will still work
      [FieldNames.TV_TIME_INTERVAL]: Yup.string()
        .oneOf(validTvIntervals)
        .required(),
      [FieldNames.TV_IDEA_URL]: Yup.string()
        .trim()
        .matches(
          /https?:\/\/www\.tradingview\.com\/chart\/[^/]+\/([a-z]|[A-Z]|[0-9]){8,}-/,
          {
            message:
              'Should be a valid TradingView Idea/Chart URL (like https://www.tradingview.com/chart/YYY/ZZZ).',
          }
        ),
    },
    // step 2
    {
      [FieldNames.PHOTO_URL]: Yup.string()
        .url()
        .trim()
        .max(255)
        .when(
          'connectors',
          requiredIfUsedAsPlaceholder(
            'signalCreated',
            `#${Placeholders.TS_CUSTOM_PHOTO_URL}#`
          )
        ),
      [FieldNames.DIRECTION]: Yup.string()
        .trim()
        .matches(/^(LONG|SHORT)$/, {
          message: 'direction can either be LONG or SHORT.',
        })
        .required(),
      [FieldNames.LEVERAGE]: Yup.number().moreThan(0).max(100).required(),
      [FieldNames.TARGET_PRICES]: yupPositionValidation
        .propertyValuesOfObjectsInArrayAreUnique(
          'Each target price must be unique.',
          (p) => p.price
        )
        .sumOfPropertyValuesOfObjectsInArrayIs(
          'Sum of target sizes must be exactly 100%.',
          (p) => p.size,
          100
        ),
      [FieldNames.ENTRY_PRICES]: yupPositionValidation
        .propertyValuesOfObjectsInArrayAreUnique(
          'Each entry price must be unique.',
          (p) => p.price
        )
        .sumOfPropertyValuesOfObjectsInArrayIs(
          'Sum of entry sizes must be exactly 100%.',
          (p) => p.size,
          100
        ),
      [FieldNames.STOP_PRICES]: yupPositionValidation
        .propertyValuesOfObjectsInArrayAreUnique(
          'Sum of stop sizes must be exactly 100%.',
          (p) => p.price
        )
        .sumOfPropertyValuesOfObjectsInArrayIs(
          'Each stop price must be unique.',
          (p) => p.size,
          100
        ),
      [FieldNames.URL]: Yup.string()
        .url()
        .trim()
        .max(255)
        .when(
          'connectors',
          requiredIfUsedAsPlaceholder(
            'signalCreated',
            `#${Placeholders.TS_CUSTOM_URL}#`
          )
        ),
      [FieldNames.NOTES]: Yup.string()
        .trim()
        .max(80000)
        .when(
          'connectors',
          requiredIfUsedAsPlaceholder(
            'signalCreated',
            `#${Placeholders.TS_NOTES}#`
          )
        ),
      [FieldNames.TIMEFRAME]: Yup.string()
        .trim()
        .max(15)
        .when(
          'connectors',
          requiredIfUsedAsPlaceholder(
            'signalCreated',
            `#${Placeholders.TS_TIMEFRAME}#`
          )
        ),
    },
    // step 3
    {
      connectors: Yup.object({
        telegram: Yup.object({
          signalCreated:
            Integrations.Telegram.Connectors.Validators.connectorDataValidator().required(),
        }).required(),
      }).required(),
    },
    // step 4
    {
      connectors: Yup.object({
        wordpress: Yup.object({
          signalCreated: Yup.object(
            Integrations.Wordpress.Connectors.Validators.signalCreated
          ).required(),
        }).required(),
      }).required(),
    },
  ]
}
